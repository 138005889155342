import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createControlledComponent } from '../../core/ControlledComponent';
import { ApplicationState, ApplicationStatus } from '../../types';
import {
  ActionsContext,
  createActionsContext,
} from '../../core/actionsContext/contextFactory';
import { ControllerActions, createActions } from '../../actions/actions';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  return {
    async pageReady() {
      const context = createActionsContext({ flowAPI });

      const { onStateChange } = await createControlledComponent<
        ApplicationState,
        ControllerActions,
        ActionsContext
      >({
        context,
        actionsFactory: createActions,
        initialState: {
          status: ApplicationStatus.INITIALIZING,
          environmentModes: context.api.getEnvironmentModes(),
        },
      });

      if (!context.api.isSSR()) {
        onStateChange((state) => {
          context?.biLogger?.update(state);
        });
      }
    },
  };
};

export default createController;
